<template>
    <nav class="flix-navbar flix-navbar-expand-lg flix-navbar-light flix-navbar-default">
      <div class="flix-container-fluid flix-html-html flix-text-center" style="font-size:inherit">

         <div id="flix-navbar-top" class="">
           <ul class="flix-nav flix-navbar-nav flix-navbar-left">
             <li class="flix-html-li">
               <router-link :to="{name: 'dashboard'}" class="flix-html-a">
                 <flixIcon id="tree-structure" /> {{ $tc('message.appointmentOverview', 1) }}
               </router-link>
             </li>
              <li class="flix-html-li">
               <router-link :to="{name: 'dashboardLogs'}" class="flix-html-a">
                 <flixIcon id="server" /> Server-Logs
               </router-link>
             </li>
             <li class="flix-html-li" v-if="$checkPermission('message', 1) && totalMessages">
               <router-link :to="{name: 'dashboardMessageCenter'}" class="flix-html-a">
                 <span class="flix-html-strong"><flixIcon :id='"bell"' /> {{ totalMessages }}</span> <span class="flix-html-small">{{ $tc('message.message', totalMessages) }}</span><span class="flix-badge flix-text-danger" v-if="unread"><flixIcon id="warning-sign" /> {{ unread }}</span>
               </router-link>
             </li>
            </ul>
            <switchLanguage class="flix-hidden-xs" />
            <ul class="flix-nav flix-navbar-nav flix-navbar-right flix-hidden-xs">
              <li class="flix-html-li">
                <router-link :to="{name: 'dashboardLogout'}" class="flix-html-a">
                  <span class="flix-text-danger">
                    <flixIcon id="log-out" /> {{ $t("message.logout") }}
                  </span>
                </router-link>
              </li>
            </ul>
            <ul class="flix-nav flix-navbar-nav flix-navbar-right">
              <li class="flix-html-li">
                <a :href="'https://bookingflix.' + (($i18n.locale === 'en')? 'net': $i18n.locale) + '/support/'" class="flix-html-a" target="_blank">
                  <flixIcon id="headset" /> {{ $t("message.supportAndBlog") }}
                </a>
              </li>
            </ul>
        </div>

      </div><!--/.container-fluid -->
    </nav>
</template>
<script>
export default {
  components: {
    switchLanguage () { return import('@/components/frontend/navigation/switchLanguage') }
  },
  data () {
    return {
      totalMessages: 0,
      variables: this.$getUserVariables(),
      unread: 0,
      organisations: false
    }
  },
  computed: {
    getOrganisationName () {
      return this.$store.getters.organisationName
    }
  },
  methods: {
    setOrganisations (ret) {
      this.organisations = ret
    },
    getUnreadMessages (callback) {
      this.$flix_post({
        url: 'dashboard/news_center',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) {
          this.unread = (ret.data[1].count1 * 1) + (ret.data[1].count2 * 1)
          callback()
        }.bind(this)
      })
    },
    getChatMessages () {
      var l = 0
      Object.values(this.$store.getters.messages).forEach(function (v) {
        l += Object.keys(v).length
      })
      return l
    },
    getDeletionList () {
      this.$getLastMonthDays({
        days: 2,
        callback: function () {
          this.$store.dispatch('getDeletionList', {
            user: this.variables.user.md5_id,
            callback: function (ret) {
              if (ret || ret !== false) {
                this.totalMessages += 1
                this.unread += 1
              }
            }.bind(this)
          })
        }.bind(this)
      })
    }
  },
  mounted () {
    this.$store.commit('cleanMessages')
    this.$store.dispatch('getMessages', function () {
      this.totalMessages = this.getChatMessages()
      // this.getUnreadMessages(function () {
      this.getDeletionList()
      // }.bind(this))
    }.bind(this))

    this.$store.dispatch('getOrganisations', this.setOrganisations)

    this.$store.dispatch('getServices', function () {})
  }
}
</script>
<style scoped lang="sass">
  @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
  .flix-badge
    background-color: white
    color: darkgrey
    display: inline-block
    margin-left: 7px
    animation: badge 1s ease-in infinite
  .router-link-exact-active
    border-bottom: 1px solid white

  @keyframes badge
    0%
      transform: scale(0.8)
    70%
      transform: scale(1)
    100%
      transform: scale(0.8)
</style>
